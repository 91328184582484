.track {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.page {
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  flex: 1;
}