.controller {
  --focus-outline: 0px;
  
  &, &:focus, &:focus-visible, &:disabled, &:hover {
    @apply transition-all ease-linear duration-200;
  }
  
  display: block;
  width: 100%;
  @apply p-2;
  @apply border border-gray-300 rounded;

  box-shadow: 
    0 0 0 var(--focus-outline) rgb(234, 234, 234)
  ;
  
  &:focus, &:focus-visible, &:focus-within {
    --focus-outline: 2px;
    outline: none;
    @apply border-gray-400;
  }
  
  &:disabled {
    @apply bg-gray-100;
  }
}