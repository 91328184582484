@import './vendors.scss';
@import './typography.scss';

:root {
  interpolate-size: allow-keywords;
}

html, body, #root {
  width: 100%;
  height: 100%;
}