.wrapper {
  min-width: 100%;
  max-width: 100%;
  overflow: scroll;
  flex-wrap: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  @apply px-6 snap-x;
}

.container {
  display: flex;
  flex-direction: row;
  min-width: fit-content;
  @apply border-x border-y border-gray-300 rounded-lg;
}

.day {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  aspect-ratio: 1;
  @apply border-gray-300 min-w-32 snap-center p-4;
  
  &:not(:first-child) {
    @apply border-l;
  }
}